import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Open the list of condition reports by clicking on the button at the left edge
of the screen.`}</p>
    <br />
    <p>{`Switch to the "Conditions" tab.`}</p>
    <Image src="features/conditionsopen.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`The list shows all condition reports in your selected map section. By default
they are sorted by date and you see the latest report at the top.`}</p>
    <br />
    <p>{`The best condition is "Perfect" with 5 of 5 points. The worst is "Bad" with
1 of 5 points. Click on the bold activity name to open the activity page and
find out more.`}</p>
    <br />
    <br />
    <h4>{`Filter`}</h4>
    <p>{`Open the filter by clicking on the filter button at the very left of the
search box.`}</p>
    <br />
    <p>{`You can filter the reports by condition. By default all conditions are
selected. Click on the condition you want to unselect and hide.`}</p>
    <Image src="features/conditionsfilter.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/reviews" mdxType="Link">
  ← Review an activity
    </Link>
    <Link className="float-right" to="/features/contactuser" mdxType="Link">
  Contact user →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      